.catalog-product-view {

  .page-title-wrapper {
    padding: 0;
    @include media-query(min, $lap-start) {
      width: calc(71% - #{$half-spacing});
      float: left;
    }

    .page-title {
      @include font-size(20px);
      @include media-query(min, $lap-start) {
        @include font-size(40px);
      }
    }
  }

  .site-footer {
    clear: both;
  }
}

.catalog-product-view-container {

  .product {

  }


  .base {
    @include font-size(24px);
  }

  .page.messages {

  }

  .columns {

  }

  .column.main {

  }
}

.product-page-dealer-search-wrapper {

}

.product-info-main {


  @include media-query(min, $lap-start) {
    width: calc(29% - #{$half-spacing});
    float: right;
    color: $white;
  }

  .container-one {
   margin-bottom: $base-spacing;
  }

  .container-two {
    background-color: $black;
    padding: $base-spacing;
    margin-bottom: $base-spacing;
  }

  .product-addto-links {
    .towishlist {
      color: $white;
      position: relative;
      text-decoration: none;
      text-align: center;
      display: block;
      @include font-size(11px);
      font-weight: bold;

      &:before {
        content: '';
        display: inline-block;
        background-color: $white;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border-color: #333333;
        position: relative;
        top: 10px;
        transform: scale(0.5);
      }
    }
  }
}
.towishlist{
  @include hover {
    &:before {
      background-color: $white;
      border: $accent 10px solid;
    }
  }
}


.product.media {
  @include media-query(min, $lap-start) {
    width: calc(35% - #{$half-spacing});
    float: left;
    margin-right: $base-spacing;
  }
}

.product.info.detailed {
  @include media-query(min, $lap-start) {
    width: 32%;
    float: left;
  }
}

.product.data.items {
  .data.item.title {
    @include visuallyhidden;
  }
}

.product.attribute.description {
  @include font-size(13px);
}


.product-info-price {
    .price {
      @include font-size(16px);
      font-weight: bold;
      color: $white;
    }
}



.stock.available {

  span {

  }
}

.availability.only {

}

.product.attribute.sku {

  .type {

  }

  .value {

  }
}

.product-reviews-summary {

}

.reviews-actions {

}

.product-page-dealer-search {
  background-color: $mercury-dk;
}

.product-page-dealer-search-header__wrapper {
  background-color: $black;
  padding: $base-spacing;
  margin-bottom: $base-spacing;
}

.product-page-dealer-search-header__title {
  margin-bottom: 0;
  text-transform: uppercase;
}

.product-page-searchlocation__container {
  padding: 0 $base-spacing;
  border-bottom: 1px solid $silver;

  .searchlocation {
    margin-bottom: 0;
  }

  .searchlocation__geolocation {
    margin-bottom: 0;
  }

  .label {
    @include media-query(min, $lap-start) {
      display: none;
    }
  }
}

.product-page-dealer-search__results-wrapper {
  padding: $base-spacing 0;
  background-color: $mercury-dk;
}

.product-page-dealer-search__dealers-list {
  list-style-type: none;
  margin-right: $base-spacing;
}

.product-page-dealer-search__dealers-item {
  a {
    position: relative;
  }

  margin-bottom: $base-spacing;

  .dealer-search__dealer-title {
    position: relative;
    color: $base-color;

    &:after {
      content: '';
      display: inline-block;
      background-image: url("../img/arrow_forward.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      width: 20px;
      height: 20px;
    }
  }

  .dealer-search__dealer-miles {
    font-weight: 300;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.box-tocart {
  margin-bottom: $base-spacing;
  .label {
    display: none;
  }

  .control {
    display: none;
  }

  .actions {
    button {
      position: relative;
      width: 100%;
      background-color: $green;
      border-color: $green;
      text-align: left;
      span {
        display: block;
        @include font-size(12px);

        &:after {
          content: '';
          position: absolute;
          display: inline-block;
          background-image: url("../img/shopping_basket.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          width: 20px;
          height: 20px;
          right: 30px;
          top: 12px;

        }
      }
    }
  }
}